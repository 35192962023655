import type { LinksFunction } from '@remix-run/node';

import brandStyles from '~/styles/brand.css';

export const links: LinksFunction = () => [
{ rel: 'preconnect', href: 'https://fonts.googleapis.com' },
{
  rel: 'preconnect',
  href: 'https://fonts.gstatic.com',
  crossOrigin: 'anonymous'
},
{
  rel: 'stylesheet',
  href: 'https://fonts.googleapis.com/css2?family=Fredoka+One&family=Montserrat+Alternates:ital,wght@0,500;0,700;0,800;1,400&display=swap',
  disabled: false
}];

export const linksWithBrandMods: LinksFunction = () => [
...links(),
{ rel: 'stylesheet', href: brandStyles }];